import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (window.sentryParams) {
  Sentry.init({
    ...window.sentryParams.config,
    // This is an  attempt to tame the extremely high volume of the same issue errors reported within
    // milliseconds for the same users https://sentry.io/organizations/espark-learning/issues/1195043589/events
    // https://docs.sentry.io/platforms/javascript/#dedupe
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event, hint) {
      // 2019/08/29 AHK: We receive a lot of messages that are uninformative because Sentry doesn't
      // handle non-Error objects properly when dealing with unhandled promise rejections.
      // This code smooths that over so that we get details.
      // See https://github.com/getsentry/sentry-javascript/issues/2210 for a discussion of the issue
      // and https://sentry.io/organizations/espark-learning/issues/1195043589/ for an example error.
      if (hint && hint.originalException && hint.originalException instanceof Event) {
        event.extra.isTrusted = hint.originalException.isTrusted;
        event.extra.detail = hint.originalException.detail;
        event.extra.type = hint.originalException.type;
      }

      return event;
    },
  });

  function pathFromUrl() {
    return window.location.href.replace(/\d+/g, ":id");
  }

  Sentry.configureScope((scope) => {
    scope.setUser(window.sentryParams.userData);

    scope.setTag("routePath", pathFromUrl());
    scope.setTag("route", window.location.href);

    scope.setTag("teacherPurpose", window.sentryParams.teacherPurpose);

    const teacher_product_features = window.sentryParams.teacherProductFeatures;

    if (teacher_product_features) {
      teacher_product_features.forEach((feature) => {
        scope.setTag("pf_" + feature, "enabled");
      });
    }
  });

  window.addTeacherBreadcrumb = function (message, data, level = Sentry.Severity.Info) {
    Sentry.addBreadcrumb({
      message,
      category: "teacher",
      data,
    });
  };

  window.Sentry = Sentry;
} else {
  window.addTeacherBreadcrumb = function () {};

  console.error("Unable to load Sentry, config missing.");
}
